@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* font-family: 'Inter', sans-serif;
font-family: 'Outfit', sans-serif; */
:root{
    --body-color: #ffffff;
    --lightborder: #eee;
    --menu-color: #454545;
    --border-color: #282828;
    --text-color: #555A66;
    --outfit: 'Outfit', sans-serif;
    --inter: 'Inter', sans-serif;
    --white: #fff;
    --boxshadow: 0 0 5px #ccc;
    --theme-orange: #e05425;
    --theme-blue: #3F63AF;    
    --theme-black: #000000;
    --copyright: #4E4E4E;
    --ping: #e619db;
    --bodybg: #F9FAFE;
    --footer-txt: #777777;
    --footer-heading: #060E1F;
    --authbg: #eff2fa;
    --authformbg: #e4eaf6;
    --borderlight: #eee;
    --red: #ff0000;
    --subhd: #32446A;
    --blueneed: #1650ce;
    --lightpink: #D96BD5;
    --lightgreen: #dae4df;
    --darkgreen: #638D2C;
    --lightred: #e4dae4;
    --darkred: #E95A50;
    --lightblue: #d5dcee;
    --darkblue: #3F63AF;
    --light-orange: #fff1e6;
}
*{
    margin: 0;
    padding: 0;
}

body{
    background-color: var(--bodybg)!important;
    font-family: var(--outfit);
    padding-bottom: 50px;
 }
 
 h1, h2, h3, h4, h5, h6, p, span, ul, li, ol, input, select, div, small{
     font-family: var(--outfit);
 }
 a{
     text-decoration: none;
 }
 ul{
    padding: 0!important;
    margin: 0!important;
 }
 li{
    list-style-type: none;
 }
 
 h2{
    font-weight: 200;
    font-size: 60px;
    line-height: 70px;
    color: var(--theme-black); 
 }

 h3{
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    color: var(--theme-black); 
 }
 
 h4{
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--theme-black); 
  }
 
  h5{
     font-weight: 600;
     font-size: 18px;
     line-height: 28px;
     color: var(--theme-black); 
  }
 
  h6{
     font-weight: 500;
     font-size: 16px;
     line-height: 24px;
     color: var(--theme-black); 
  }
 
 p{
     font-weight: 400;
     font-size: 17px;
     line-height: 24px;
     color: var(--text-color); 
  }
  a{
    text-decoration: none!important;
  }

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
   
::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color: var(--lightblue);
  }
   
::-webkit-scrollbar-thumb {
    background-color: var(--theme-blue);
    outline: 1px solid var(--white);
    border-radius: 50px;
  }
 
  .commonButton{
     border: none;
     outline: none;
     /* background-color: var(--green); */
     border-radius: 50px;
     padding: 8px 10px;
     font-size: 15px;
     font-weight: 400;
     color: var(--white);
     min-width: 100px;
     flex: none;
     border: 2px solid transparent;
     font-family: var(--inter);
     font-weight: 500;
  }
  .commonButton:hover{
     background-color: var(--border-color);
     color: var(--white);
  }
  .commonButton a{
     color: var(--white);
  }
  .form-control{
     border-radius: 50px!important;
     height: 50px;
     font-size: 16px!important;
     box-shadow: none!important;
     border: 1px solid #D6E3FF!important;
     padding: 5px 20px!important;
  }
  select.form-control{
     /* background: url("../images/dropdown.png") no-repeat; */
     background-size: 12px;
     background-position: calc(100% - 15px) 17px;
  }
  

  .buttonSubmit{
    background-color: var(--theme-blue);
    width: 100%;
    box-shadow: 0 4px 8px #ccc;
  }
  .buttonSubmit:hover{
    background-color: var(--theme-orange);
  }

  .hollowBtn1{
    border-color: var(--theme-orange);
    color: var(--theme-orange);
    background-color: transparent;
  }
  .hollowBtn2{
    border-color: var(--theme-blue);
    color: var(--theme-blue);
    background-color: transparent;
  }

.mainBase{
    position: relative;
    padding-top: 100px;
}
/* -------------NAVIGATION-CSS-START------------ */
.navigationBg{
    padding: 15px 0!important;
    position: fixed!important;
    width: 100%;
    top: 0;
    z-index: 999;
}
  .navigationBg .navbar-nav a{
    font-family: var(--inter);
    color: var(--menu-color);
    font-size: 16px;
    font-weight: 500;
    margin: 0 10px;
    padding: 5px 10px;
  }
  .dropdown-menu a:hover{
    background-color: var(--light-orange);
  }
  .navigationBg .navbar-nav a:hover{
    color: var(--theme-orange);
  }
  .navbar-brand img{
    width: 230px;
  }
  .stickyHead .navigationBg{
    background-color: var(--body-color);
    box-shadow: 0 0 5px #ccc;
  }

  .navbar-nav .dropdown-menu a{
    margin: 0;
    padding: 8px 20px;
    display: block;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu{
    min-width: 225px;
  }

  .css-1wvake5.ps-collapsed{
    /* width: 47px!important;
    min-width: 47px!important; */
    width: 0px!important;
    min-width: 0px!important;
  }
  .css-1wvake5.ps-collapsed .ps-menu-button{
    font-size: 0;
    /* text-align: center; */
  }
  .css-1wvake5.ps-collapsed .ps-menu-button i{
    margin-right: 0px;
  }
  .css-1wvake5.ps-collapsed .ps-menu-icon{
    min-width: auto;
  }
/* -------------NAVIGATION-CSS-END------------ */



/* -------------HOME-CSS-START------------ */
.bannerBg{
  padding-bottom: 50px;
  padding-top: 40px;
  min-height: 500px;
}
.bannerBg h1{
  font-weight: 200;
  font-size: 45px;
  line-height: 50px;
  padding-bottom: 15px;
}
.getStartbtn{
  background-color: var(--theme-blue);
  box-shadow: var(--boxshadow);
}
.bannerBg img{
  position: absolute;
  right: 0;
  width: 80%;
  max-width: 650px;
}
.homeVideo{  
  text-align: center;
  padding: 30px 20px 0 20px;
  position: relative;
  z-index: 0;
}
.homeVideo::after{
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 300px;
  width: 100%;
  background-color: #f2f5fb;
}
.homeVideo h2{
  font-weight: 300;
  font-size: 35px;
  line-height: 40px;
  color: #32446A; 
}
.homeVideo h6{
  color: #32446A;
}
.videoBase{
  padding: 0 20%;
}
.videoBase img{
  width: 100%;
}
.needBg{
  padding: 50px 0;
  background-color: var(--blueneed);
  background-image: url(../images/rainbow.png);
  background-position: 0 0;
  background-size: 80%;
  background-repeat: no-repeat;
}
.needBg h3{
  font-weight: 300;
}
.needBg h3, .needBg h6{
  color: var(--white);
  text-align: center;
}

.need_a{
  background-color: #134084;
  box-shadow: 0 0 3px #134084;
  border-radius: 6px;
  padding: 25px 15px;
  height: 100%; 
}
.need_a img{
  filter: invert(100%) sepia(13%) saturate(2%) hue-rotate(161deg) brightness(106%) contrast(101%);
  width: 35px;
}
.need_a h6{
  text-align: left;
  padding-top: 12px;
}
.need_a p{
  color: var(--white);
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}
.smartAccountbg{
  padding: 50px 0;
}
.smartAccountbg h2{
  color: #32446A;
  font-weight: 300;
  font-size: 2.3rem;
}
.smartAccountbg h2 span{
  font-size: 23px;
}
.smartAccountbg  h5{
  /* text-transform: uppercase; */
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 1px;
}
.smartAccountbg p{
  color: #32446A;
}
.accRight{
  text-align: center;
}
.accRight img{
  /* width: 60%; */
  max-width: 100%;
}
.manageBusiness{
  background-color: #DBE2F1;
  text-align: center;
  padding: 50px 0;
}
.manageBusiness h2{
  font-weight: 300;
  color: #32446A;
}
.slideBlock{
  background-color: var(--white);
  border-radius: 6px;
  padding: 10px;
  text-align: center;
}
.slideBlock h6{
  color: #32446A;
  padding: 15px 0;
}
.slideBlock img{
  border-radius: 10px;
}
.manageBusiness .owl-nav button{
  background-color: transparent!important;
}
.manageBusiness .owl-nav button:hover{
  color: var(--theme-orange)!important;
}
.taxseason{
  padding: 50px 0;
}
.taxbase{
  display: flex;
  box-shadow: 0 0 10px #e8ecf7;
  border-radius: 6px;
  overflow: hidden;
}
.taxLft{
  flex: none;
  padding: 20px;
  width: 50%;
}
.taxRgt{
  flex: none;
  background-color: #134084;
  padding: 20px 20px 20px 60px;
  width: 50%;
}
.taxRgt h2{
  color: var(--white);
  font-weight: 400;
}
.taxRgt h6{
  color: var(--white);
}
.taxRgt p{
  color: var(--white);
  font-weight: 300;
}
.taxLft img{
  position: relative;
  right: -40px;
}
.homefaqBg{
  padding: 50px 0;
  text-align: center;
}
.homefaqBg h2{
  font-weight: 300;
  color: #32446A;
}
.homefaqBg .accordion-item{
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #ccc;
  border-radius: 0!important;
}
.homefaqBg .accordion-button{
  background-color: transparent!important;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none!important;
  color: #32446A;
  font-size: 18px;
}
.homefaqBg .accordion-button::after{
  content: "\f055";
  background-image: none;
  transition: none;
  font-family: "Font Awesome 6 Pro";
}
.homefaqBg .accordion-button:not(.collapsed)::after {
  content: "\f056";
  background-image: none;
  transition: none;
  font-family: "Font Awesome 6 Pro";
  transform: none;
}
.homefaqBg .accordion-body{
  text-align: left;
  background-color: var(--white);
  margin-bottom: 15px;
  border-radius: 6px;
  box-shadow: 0 0 51px #e8ebf5;
}
.faqDesc{
  display: flex;
  align-items: flex-start;
}
.faqDesc img{
  margin-right: 15px;
}
.faqDesc p{
  margin: 0;
}

.testimonial{
  padding: 50px 0;
  background-color: #F5F6FC;
}
.testiTm{
  margin-top: 65px;
}
.testimonial h2{
  color: #32446A;
  font-weight: 300;
  text-align: center;
  position: relative;
  z-index: 0;
}
.testimonial h2::before {
  content: "Testimonials";
  position: absolute;
  width: 100%;
  left: 0;
  top: 20px;
  text-align: center;
  color: #e8edf7;
  font-size: 90px;
  font-weight: 800;
  text-transform: uppercase;
  z-index: -1;
}

.testimonial .owl-carousel .owl-stage-outer{
  padding-top: 30px;
  padding-bottom: 30px;
}

.testimonial .owl-item.active .testiBlock{
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0 0 5px #ccc;
  text-align: center;
  padding: 20px;
}
.testimonial .owl-item.active{
  position: relative;
    z-index: 1;
}

.testimonial .owl-item.active.center{
  transform: scale(1.2)!important;
  -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
    filter: grayscale(0); 
    position: relative;
    z-index: 9;  
}
/* .testimonial .active.center .item{
  z-index: 99;
  position: relative;
} */
.testimonial .active{
  transform: scale(.8)!important;
  transition: .9s ease;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);    
}
.testimonial .owl-item.active.center .testiBlock{  
  background: #16A2F3 url(../images/quote.png) 0 0 no-repeat;
  background-size: 100px;
}
.testiBlock img{
  width: 130px!important;
  display: inline-block!important;
}
.testiBlock h6{
  font-weight: 600;
}
.testimonial .owl-item.active.center .testiBlock h6{
  color: var(--white);
}
.testimonial .owl-item.active.center .testiBlock p{
  color: var(--white);
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
}
.testimonial .owl-theme .owl-dots .owl-dot.active{
  filter: none!important;
}
.testimonial .owl-theme .owl-dots .owl-dot.active span, .testimonial .owl-theme .owl-dots .owl-dot:hover span {
  background: #16A2F3;  
}


.restUs{
  padding: 80px 0;
  background: url(../images/homelastbg.jpg) 0 0 no-repeat;
  background-size: cover;
  text-align: center;
}
.restUs h2{
  font-weight: 200;
  color: var(--white);
}
.restUs h2 span{
  color: #3F63AF;
}
.restUs button{
  color: var(--white);
  background-color: var(--theme-orange);
  box-shadow: 0 0 5px #2c2c2c;
  width: 150px;
}
/* -------------HOME-CSS-END------------ */



/* -------------AUTH-CSS-START------------ */
.authBg{
  background: var(--authbg);
  padding-bottom: 50px;
}

.authLogo{
  text-align: center;
  padding: 60px 0 20px;
  position: relative;
  z-index: 0;
}
.authLogo img{
  width: 350px;
}
.authLogo::before{
  content: "1simpleclick";
  position: absolute;
  width: 100%;
  left: 0;
  top: 20px;
  text-align: center;
  color: #e8edf7;
  font-size: 150px;
  font-weight: 900;
  text-transform: uppercase;
  z-index: -1;
}
.cfoLogo{
  padding: 0;
  height: 140px;
}
.cfoLogo::before{
  font-size: 140px;
  line-height: 140px;
  top: 0;
}
.cfoBnr{
  margin-top: -100px;
  min-height: auto;
  padding-bottom: 60px;
}
.cfoBnr h1{
  padding-top: 130px;
}
.cfoBnr img{
  max-width: 580px;
}
.cfoBtm{
  background-color: #e6ebfb;
  padding: 60px 0;
}
.cfoBtm img{
  width: 100%;
  border-radius: 20px;
}
.cfoBtm h5{
  color: var(--darkblue);
}
.cfoBtm .taxTxt li{
  color: var(--border-color);
}
.signForm{
  background-color: var(--authformbg);
  box-shadow: var(--boxshadow);
  position: relative;
  max-width: 800px;
  margin: 25px auto 0 auto;
  border-radius: 8px;
  overflow: hidden;
}
.signFormbase{
  background-color: var(--white);
  border-radius: 8px 8px 0 0;
  padding-bottom: 20px;
}
.accountTxt{
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  color: var(--theme-blue);
  padding: 20px;
}
.accountTxt a{
  color: var(--theme-orange);
}
.signHdn{
  font-size: 23px;
  font-weight: 500;
  color: var(--theme-blue);
  text-align: center;
  border-bottom: 1px solid var(--borderlight);
  padding: 18px 0;
}
.showHide{
  position: relative;
}
.eyeAbs{
  position: absolute;
  right: 13px;
  top: 6px;
  color: var(--theme-blue);
}
.loginEye{
  top: 12px;
}
.signMainform{
  padding: 20px 40px;
}
.signMainform label{
  padding-left: 22px;
  padding-bottom: 5px;
  font-size: 17px;
  line-height: 22px;
  padding-right: 15px;
  color: #686BB0;
}
.signMainform .errorTxt{
  padding-left: 21px;
  line-height: 30px;
}
.forgotPass{
  text-align: right;
  padding-bottom: 25px;
}
.forgotPass a{
  color: #3F63AF;
}
a:hover{
  /* color: var(--theme-orange); */
}
.errorTxt{
  color: var(--red);
}
.regdLogn img{
  width: 280px;
}
.authBg p span{
  color: var(--theme-blue);
}
/* .watermrk img{
  width: 100%;
} */
.regdTemplate{
  position: relative;
  background: url("../images/watermark.png") 0 0 no-repeat;
  background-size: 100%;
  padding-top: 5px;
}
.regdTemplate img{
  width: 90%;
}
.regdLeft{
  /* padding-right: 50px; */
}

.termsTxt{
  text-align: center;
  font-size: 15px;
  line-height: 21px;
  font-weight: 400;
  color: var(--footer-txt);
  padding: 20px;
}
.termsTxt a{
  color: var(--theme-blue);
}
.termsTxt a:hover{
  color: var(--theme-orange);
}
.fieldBlock{
  position: relative;
}
.fieldBlock label{
  position: absolute;
  z-index: 0;
  left: 20px;
  top: 12px;
  padding: 0;
}
.fieldBlock label img{
  position: relative;
  /* top: 2px; */
}
.fieldBlock input{
  padding-left: 45px!important;
}
.fieldBlock input::placeholder{
  color: var(--theme-blue);
}
.fieldBlock select{
  padding-left: 45px!important;
  color: var(--theme-blue);
}
.phoneInputDropdown{
  position: relative;
  z-index: 8;
}
.phoneInputDropdown input[type="tel"], .phoneInputDropdown button{
  border: none;
  padding: 0!important;
}
.phoneInputDropdown .form-control{
  padding-left: 43px!important;
}
.noBordr{
  border-bottom: 0;
}

.fieldBlock .css-13cymwt-control{
  border: 1px solid #D6E3FF;
  height: 50px;
  border-radius: 50px;
  padding-left: 40px;
  background-color: transparent;
}

.verifyOtp{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 0px 0;
}
.verifyOtp input{
  width: 40px!important;
  height: 45px;
  border: 1px solid #ccc;
  margin: 0 5px;
  border-radius: 7px;
  font-size: 15px;
}
.verifyOtp button{
  max-width: 250px;
  margin-top: 20px;
}
.verifyOtp p{
  margin: 0;
}
.resend{
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--theme-blue);
}
.resend:hover{
  color: var(--theme-orange);
}
.securecode{
  color: var(--theme-blue);
}

/* -------------AUTH-CSS-END------------ */


/* -------------PROFILECREATION-CSS-START------------ */
.subhead{
  position: relative;
  text-align: center;
}
.authLogo h5{
  color: var(--subhd);
}
.proflogo{
  background-color: #fff;
  text-align: center;
  padding: 15px 0;
}
.proflogo img{
  width: 250px;
}
.brwseFile{
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.brwseInst{
  padding-left: 20px;
}
.brwseInst p span{
  color: var(--theme-blue);
}
.upld{
  text-align: center;
  border: 1px solid #D6E3FF;
  padding: 15px;
}
.upld p{
  font-size: 14px;
  line-height: 20px;
  color: #686BB0;
}
.upld label{
  padding: 0;
  display: flex;
  flex-direction: column;
}
/* -------------PROFILECREATION-CSS-END------------ */

.flerr{
  position: absolute;
  right: 0;
  top: 0;
}
.needfield{
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 10px;
  font-size: 22px;
  color: var(--theme-blue);
}
.appendbtn{
  border: none;
  outline: none;
  background-color: transparent;
  box-shadow: none;
  font-size: 16px;
  color: var(--theme-blue);
}
.delIcon{
  border: none;
  outline: none;
  border-radius: 100px;
  font-size: 20px;
  background-color: #FFF0E8;
  color: var(--theme-orange);
  width: 55px;
  height: 55px;
}

.subscribe{
  padding: 20px 40px;
  text-align: center;
}
.choosePlanTop{
  background-color: var(--theme-blue);
  text-align: center;
  padding: 60px 30px 75px;
}
.choosePlanTop h3{
  color: var(--white);
}
.choosePlanTop p{
  color: #B0C6F3;
}
.choosePlanBtm{
  background-color: var(--white);
  padding: 0 50px 50px;
}
.planBg{
  box-shadow: var(--boxshadow);
  border-radius: 10px;
  overflow: hidden;
  margin-top: -55px;
}
.plantop{
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #E8E9FB;
  position: relative;
}
.orangebg{
  background-color: var(--theme-orange);
  color: var(--white);
}

.cynebg{
  background-color: var(--ping);
  color: var(--white);
}
.plantop h5{
  font-size: 18px;
}
.plantop button{
  border: none;
  outline: none;
  /* background-color: #E8E9FB; */
  background-color: #2666ef;
  border-radius: 50px;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 500;
  /* color: var(--theme-blue); */
  color: var(--white);
}
.plancontent{
  padding: 20px;
  background-color: var(--white);
}
.plancontent h2{
  font-size: 50px;
  font-weight: 300;
}
.plancontent h2 small{
  font-size: 15px;
  color: #7E8EAF;
}
.plancontent p{
  color: #7E8EAF;
}
.plancontent ul{
  padding: 15px 0!important;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px!important;
  min-height: 380px;
}
.plancontent ul li{
  background: url(../images/check.png) 0 3px no-repeat;
  background-size: 20px;
  padding-left: 25px;
  font-size: 16px;
  line-height: 22px;
  color: #7E8EAF;
  padding-bottom: 8px;
}
.form-floating>label{
  left: 5px!important;
}
.form-floating label::after{
  display: none;
  background: transparent;
}
.form-floating>.form-control:focus~label{
  /* top: -2px;
  left: 5px; */
}
.form-floating > input{
  padding-top: 13px!important;
}
.tdFlex{
  display: flex;
  justify-content: center;
}
.tdFlex .dropRow{
  min-width: 28px;
}
.highlight{
  color: var(--theme-blue);
  font-weight: 600;
}
.dflex{
  display: flex;
}

.spinner{
  position: fixed;
  z-index: 9999;
  display: flex!important;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    border-color: rgb(32, 41, 70);
    background-color: rgba(0,0,0,0.5);
}


















.pflex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
}
.process{
 position: relative;
 z-index: 1;
 font-size: 18px;
 color: var(--theme-blue);
 font-weight: 500;
}
.process span{
  position: relative;
  z-index: 2;
  display: inline-block;
}
.proc1{
 display: inline-block;
 position: relative;
}
.proc1::before{
  content: "Yes";
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--white);
  border-radius: 50px 0 0 50px;
}
.proc2{
  display: inline-block;
  position: relative;
}
.test1 label, .test2 label{
  padding-bottom: 0;
}

.activeColor{
  background-color: var(--theme-orange);
}
.activeColor label{
  color: var(--white);
}
.process [type="radio"]:checked,
.process [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

/* 

.process [type="radio"]:checked + label
{
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    line-height: 34px;
    display: inline-block;
    color: var(--white);
    padding: 0 20px 0 20px;
    background-color: var(--theme-orange);
}
.process [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    line-height: 34px;
    display: inline-block;
    color: var(--border-color);
    padding: 0 20px 0 20px;
    padding-bottom: 0;
}
.rad2 label::after{
  border-radius: 0 50px 50px 0!important;
} */
/* .process [type="radio"]:checked + label:before,
.process [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 34px;
    background: #fff;
} */
.prcs{
  overflow: hidden;
  box-shadow: 0 0 50px #ccc;
  border-radius: 50px;
  display: inline-table;
  margin-left: 10px;
}
.test1::before{
  border-radius: 50px 0 0 50px;
  overflow: hidden;
}
/* .process [type="radio"]:checked + label:after,
.process [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 100%;
    height: 34px;
    background: var(--theme-orange);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    border-radius: 50px 0 0 50px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.process [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.process [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
} */









/* ------------------------------------------------- */
.paymentShow{
 background-color: #3F63AF;
 display: flex;
    justify-content: center!important;
    align-items: center;
    color: #fff;
    position: relative;
}
.paymentShow button{
  position: absolute;
  right: 20px;
  color: #fff;
}
.radio{
  position: relative;
  margin-bottom: 10px;
}
.subscriptionRado img{
  width: 25px;
}
.subscriptionRado [type="radio"]:checked,
.subscriptionRado [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.subscriptionRado [type="radio"]:checked + label,
.subscriptionRado [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #7E8EAF;
}
.subscriptionRado [type="radio"]:checked + label:before,
.subscriptionRado [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
.subscriptionRado [type="radio"]:checked + label:after,
.subscriptionRado [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 9px;
    height: 9px;
    background: var(--theme-orange);
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.subscriptionRado [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.subscriptionRado [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* --------------------------------- */
.pricingshadow{
  background-color: transparent;
  box-shadow: none;
}
.pricingA{
  background-color: transparent;
  padding-top: 0;
}
.pricingA h2, .pricingA p{
  color: #3F63AF;
}
.pricingB{
 background-color: transparent;
}

/* -------------------------dashboard-start------------------------ */

/* -----------Left-panel-menu-start------------ */
.dbsidemenu{
  background-color: #3f63af;
  /* padding-right: 10px; */
  border: none!important;
}
.dbsidemenu .ps-sidebar-container{
  background-color: transparent;
}
.dbsidemenu nav{
  padding: 0 10px 0 20px;
}
.dbsidemenu li .ps-menu-button{
  padding-left: 8px;
  padding-right: 8px;
  color:var(--white);
  font-size: 17px;
  font-weight: 400;
}
.ps-submenu-root .ps-menu-icon{
  margin-right: 0;
  width: 28px;
  min-width: 28px;
}
.dbsidemenu li .ps-menu-button i{
  font-size: 20px;
  margin-right: 10px;
}
.dbsidemenu li .ps-menu-button:hover{
  background-color: var(--theme-orange);
  color: var(--white);
}
.dbsidemenu li.show > .ps-menu-button{
  background-color: var(--theme-orange);
  color: var(--white);
}
.dbsidemenu .ps-submenu-content li.show > .ps-menu-button{
  background-color: var(--theme-orange);
  color: var(--white);
}
.ps-submenu-content i{
  color: var(--theme-orange);
}
.ps-submenu-content .ps-menu-button:hover i{
  color: var(--white);
}
.dbsidemenu .ps-submenu-content{
  background-color: transparent;
  margin-bottom: 20px;
}
.dbsidemenu .ps-submenu-content li .ps-menu-button{
  font-size: 15px;
  height: auto;
}
.dbsidemenu .ps-submenu-content span.ps-menu-label{
  display: flex;
  white-space: normal;
  border-bottom: 1px solid #5479c7;
  padding:7px 0;
}
.menuToggle{
  border: none;
  background-color: transparent;
  font-size: 18px;
  line-height: 18px;
  margin-left: 7px;
  box-shadow: none!important;
  color: var(--theme-blue);
  position: fixed;
  z-index: 9999;
  right: 10px;
  top: 10px;
  display: none;
  background: #fff;
  padding: 2px;
}
.css-1wvake5.ps-collapsed .ps-submenu-expand-icon{
  display: none; 
}
.sideLogin{
  background-color: #395EAA;
  padding: 12px 10px 12px 20px;
  margin-bottom: 20px;
}
.logoutLeft{
  border-top: 1px solid #5479c7;
  padding: 30px 12px 20px 20px;
  text-align: center;
  margin-top: 20px;
}
.logoutLeft button{
  background-color: #26488F;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
}
.logoutLeft button:hover{
  background-color: var(--theme-orange);
}

/* -----------Left-panel-menu-end------------ */

.customContainer{
  width: 100%!important;
  max-width: inherit!important;
  padding: 0!important;
}
.dbMain{
  padding-top: 85px;
}
.dbMain nav{
 background-color: var(--white);
}
.dashboard{  
  background-color: var(--white);
}
.pdt{
  padding-top: 69px;
}
.dbTopsec{
  display: flex;
  justify-content: space-between;
  padding-bottom: 7px;
}
.dbTopsec h5{
  color: #32446A;
}
.dbHead{
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  padding: 15px 20px;
  box-shadow: 0 0 5px #b5cfda;
  background-color: var(--white);
}
.dbHeadRight{
  display: flex;
}
.logoBg img{ width: 200px;}
.chatBase, .notifyBase{
  margin: 0 15px;
}
.chatBase button, .notifyBase button{
  padding: 2px 10px;
  border: none;
  font-size: 22px;
  color: var(--white);
  background-color: var(--theme-blue);
}
.chatBase button:hover, .notifyBase button:hover{
  background-color: var(--theme-orange);
  color: var(--white);
}
.chatBase button::after, .notifyBase button::after, .userBase button::after{
  display: none;
}
.userBase{
  margin-left: 15px;
}
.userBase button{
  padding: 0;
  border: none;
  font-size: 20px;
  color: var(--theme-orange);
}
.userBase img{
  width: 35px;
  border-radius: 100px;
  height: 35px;
  object-fit: cover;
  border: 2px solid #ccc;
  background-color: var(--white);
}
.userBase .dropdown-menu{
  min-width: auto;
  padding: 0;
  overflow: hidden;
}
.userBase .dropdown-menu a{
  color: var(--theme-blue);
  padding: 10px;
}
.userBase .dropdown-menu a:hover{
  background-color: var(--theme-orange);
  color: var(--white);
}
.payble{
  background-color: #f3f5fc;
  border-radius: 6px;
  padding: 15px;
}
.payble h5{
  color: #32446A;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.payflex {
  display: flex;
  flex-direction: column;
  padding-bottom: 19px;
  color: #5D6065;
  font-weight: 500;
  justify-content: space-between;
}
.payprice{
  color: var(--blueneed);
  min-width: 88px;
 
}
.payftxt{
  display: inline-block;
  padding-right: 10px;
}
.maindbBase{
  display: flex;
  position: relative;
}
.dbTxtbase{
  padding: 20px;
  flex: 1;
  background-color: var(--authbg);
}
.left-menu{
  width: 200px;
}
.dbTop{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.dbSection2{
  border-radius: 12px;
  overflow: hidden;
}
.dbSection2 img{
  width: 100%;
}
.orderSlab{
  border-radius: 10px;
  text-align: center;
  padding: 15px 10px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.orderSlab h5, .orderSlab h6{
  margin: 0;
  font-weight: 700;
}
.orderSlab small{
  color: #787486;
}
.slab1{
  background-color: #FFE2CF;
}
.slab2{
  background-color: #DDD9FF;
}
.slab3{
  background-color: #C9EEFF;
}
.ovViewtop{
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding: 18px 20px 7px;
}
.ovViewtop h5{
  color: #38486A;
}
.ovViewtop select{
  border-radius: 50px;
  border: 1px solid #ccc;
  padding: 2px 10px;
  color: var(--copyright);
}
.incexp{
  color: var(--copyright);
  font-size: 15px;
}
.incexp span{
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}
.inc{
  background-color: #2D9CDB;
  border-radius: 100px;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.exp{
  background-color: #FF5B5B;
  border-radius: 100px;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.ovViewbtm{
  padding: 5px 20px;
}
.pie img{
  width: 100%;
}
.ovViewbtm span{
  display: inline-block;
}
.overSubbtm{
  display: flex;
  justify-content: space-between;
  padding-bottom: 13px;
  color: #38486A;
  font-weight: 500;
}
.overSubbtm i{
  color: var(--theme-orange);
  margin-right: 6px;
}
.commonWhite{
  background-color: var(--white);
  border-radius: 10px;
}
.commonBlue{
  background-color: var(--theme-blue);
  border-radius: 10px;  
}
.dbBlue{
  padding: 20px;
  display: flex;
}
.dbBlue h2{
  color: #fff;
}
.addLst{  
  padding-left: 35px;
}
.dbBlue p{
  color: #7CA4FA;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}
.dbBlue i{
  color: var(--white);
  margin-right: 6px;
}
.john{
  font-size: 15px;
  color: var(--white);
}
.incTable{
  overflow: hidden;
}
.incTable table{
  width: 100%;
}
.incTable th{
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: var(--lightpink);
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
}
.incTable td{
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
}
.footerCopy{
  text-align: center;
  padding: 20px;
  font-size: 13px;
  color: #9DA5B5;
}
.show{
  display: block;
}
.show .ps-submenu-content{
  display: block;
}
.customerMain{
  /* padding: 20px; */
  flex: 1;
  background-color: var(--authbg);
  overflow: auto;
}
.custTop{
  background-color: #E1E7F3;
  padding: 15px 20px 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7E89A1;
  font-size: 18px;
  font-weight: 400;
}
.custTop nav{
  padding: 0;
  background-color: transparent!important;
}
.custTop a{
  color: #7E89A1;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  display: inline-block;
  margin: 0 16px;
  padding: 0 10px;
  position: relative;
}
.custTop a::before{
  content: "";
  position: absolute;
  right: -17px;
  top: 0;
  width: 1px;
  background-color: #cad0dd;
  height: 100%;
  display: inline-block;
}

.custTop a:hover, .custTop a.active{
  color: var(--theme-blue);
}
.custTop a:hover::after, .custTop a.active::after{
  content: "";
  position: absolute;
  left: 0;
  top: 29px;
  width: 100%;
  height: 2px;
  background-color: var(--theme-blue);
  display: block;
}
.custTop select{
  display: inline-block;
  width: auto;
  background-color: transparent;
  border: none!important;
  outline: none;
  box-shadow: none!important;
  color: #7E89A1;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  padding: 0 26px 0 10px;
  margin-left: 17px;
}
.custTop select.active{
  color: var(--theme-blue);
  position: relative;
}
.srtBy{
  display: flex;
  align-items: center;
}
.srtBy label{
  flex: none;
}
.custBtm{
  padding: 20px;
}
.customerSrchTop{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addBtn{
  background-color: #D6E3FF;
  color: var(--theme-blue);
  font-size: 14px;
  padding: 5px 20px;
}
.addBtn:hover{
  background-color: orange;
}
.addBtn:hover a{
  color: #fff;
}

.addBtn a{
  color: var(--theme-blue);
}
.addBtn a:hover{
  color: var(--white);
}
.expBtn{
  background-color: var(--theme-blue);
  color: var(--white);
  font-size: 14px;
  padding: 7px 20px;
}
.expBtn a{
  color: var(--white)!important;
}
.customerSrchTop button:hover{
  background-color: var(--theme-orange);
}
.gridList button{
  border: none;
  background-color: transparent!important;
  box-shadow: none!important;
  color: var(--theme-blue);
  font-size: 23px;
  margin-left: 10px;
}
.listViews table {
  border-radius: 10px;
  /* overflow: hidden; */
}


.listViews table thead tr th:nth-child(1){
  border-radius: 10px 0 0 0;
}
.listViews table thead tr th:last-child{
  border-radius: 0 10px 0 0;
}
.listViews table tbody tr:last-child td:nth-child(1){
  border-radius: 0 0 0 10px;
}
.listViews table tbody tr:last-child td:last-child{
  border-radius: 0 0 10px 0;
}
.listViews table tbody tr:last-child td{
  border-bottom: 0;
}


.listViews table th{
  background-color: #fff;
  padding: 10px 15px;
  color: var(--theme-blue);
  font-weight: 500;
  font-size: 15px;
}
.listViews table td{
  background-color: #e2e6f2;
  padding: 10px 15px;
  color: #48597D;
  font-weight: 400;
  font-size: 14px;
  border-top: 4px solid var(--authbg);
}
.listViews table td:last-child, .listViews table th:last-child{
  text-align: center;
}
.listViews table tr:hover td{
  background-color: var(--white);
  color: var(--copyright);
}
.viewIcon{
  display: inline-block;
  margin: 0 5px;
  font-size: 15px;
  border-right: 1px solid #ccc;
  padding-right: 10px;
}
.viewIcon:hover{
  color: var(--blueneed);
}
.orange{
  color: var(--theme-orange);
}
.gridView{
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
}
.gridView .card{
  width: 31%;
  margin: 0 1.15% 20px;
  /* flex: 1;
  min-width: 31%; */
}
.cardBlock{
  /* display: flex; */
  padding-bottom: 7px;
  font-size: 14px;
}
.cardBlock h6{
  font-weight: 500;
  margin: 0;
  color: var(--theme-blue);
  font-size: 16px;
  line-height: 20px;
}
.actn{
  display: flex;
}
.customSrchfield{
  position: relative;
  display: flex;
}
.customSrchfield input{
  border: none;
  outline: none;
  padding: 5px 10px 5px 38px;
  font-size: 14px;
  background-color: var(--white);
  height: 40px;
  border-radius: 5px;
}
.customSrchfield span{
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 8px;
}
.backpage{
  color: var(--theme-blue);
  margin-right: 10px;
}
.addCustomerForm input, .addCustomerForm textarea, .civModal input{
  border-radius: 0!important;
  border: none!important;
  background-color: #f1f2f9;
  padding: 5px 10px!important;
  height: 40px;
  font-size: 14px!important;
  font-weight: 400;
}
.addCustomerForm .custom-dropdown{
  border-radius: 0!important;
  border: none!important;
  background-color: #f1f2f9;
  padding: 5px 10px!important;
  height: 40px;
  font-size: 14px!important;
  line-height: 30px;
  font-weight: 400;
}
.addCustomerForm .dropdown-toggle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #818181;
}
.addCustomerForm .dropdown-toggle::after{
  background-image: url('../images/downarrow.png');
  border: none;
  width: 20px;
  height: 20px;
  background-size: 20px;
}
.civModal input{
  height: 40px!important;
  min-height: auto!important;
  font-size: 15px;
  color: var(--border-color);
}
.civModal label{
  padding: 4px!important;
  color: var(--border-color);
  font-size: 15px;
}
.addCustomerForm textarea{
  line-height: 20px;
  resize: none;
  height: 208px;
}
.addCustomerForm input:focus, .addCustomerForm textarea:focus{
  background-color: #f1f2f9;
}
.addCustomerForm select{
  border-radius: 0;
  border: none;
  background-color: #f1f2f9;
  padding: 5px 10px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
}
.dropselect button{
  border-radius: 0;
  border: none;
  background-color: #f1f2f9!important;
  padding: 5px 10px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-align: left;
  color: var(--copyright)!important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropselect .dropdown-menu{
  right: 1px!important;
  left: inherit!important;
}
.dropselect button::after{
  background-image: url('../images/downarrow.png');
  border: none;
  width: 20px;
  height: 20px;
  background-size: 20px;
}
.radioline{
  display: flex;
  align-items: center;
}
/* .radioline input{
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.radioline label{
  padding: 0;
}
.radioline input:checked ~ label{
  background-color: var(--blueneed);
}
.radioRel{
  position: relative;
} */

.radioline [type="radio"]:checked,
.radioline [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.radioline [type="radio"]:checked + label,
.radioline [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 24px;
    display: inline-block;
    color: #333;
    z-index: 2;
}
.radioline [type="radio"]:checked + label{
  color: #fff;
}
/* .radioline [type="radio"]:checked + label:before,
.radioline [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
    display: none;
} */
.checkedToggle {
  content: '';
  width: 38px;
  height: 26px;
  background: var(--theme-blue);
  position: absolute;
  z-index: -1;
  top: 0;
  right: -8px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.radioline [type="radio"]:checked + label:after,
.radioline [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 38px;
    height: 26px;
    background: var(--theme-blue);
    position: absolute;
    z-index: -1;
    top: 0;
    right: -8px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.radioline [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.radioline [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.radioline label{
  /* padding-left: 0!important; */
}
.radioline label::before{
  content: '';
  width: 38px;
  height: 26px;
  background: var(--theme-blue);
  position: absolute;
  z-index: -1;
  top: 0;
  right: -8px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-color: #f1f2f9;
}
.phInp{
  width: 100%;
}
.phInp input[type="tel"]{
  border-radius: 0!important;
  border: none!important;
  background-color: #f1f2f9;
  padding: 5px 10px!important;
  height: 40px;
  font-size: 14px!important;
  font-weight: 400;
  width: 100%;
}
.phInp button{
  height: 100%;
}
.serviceTool{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.commonSt{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 30px 20px;
  width: 18.5%;
}
.commonSt img{
  width: 30px;
  margin-bottom: 15px;
}

.st1{
  background-color: #87BAF5;
}
.st1 h6{
  color: #1E599E;
}

.st2{
  background-color: #FF975C;
}
.st2 h6{
  color: #863609;
}

.st3{
  background-color: #BA91CD;
}
.st3 h6{
  color: #571078;
}

.st4{
  background-color: #3F63AF;
}
.st4 h6{
  color: #9DBDFF;
}

.st5{
  background-color: #E2CD60;
}
.st5 h6{
  color: #8D780A;
}
.income{
  background-color: #FFE2CF;
  padding: 15px;
  border-radius: 10px;
}
.income h5{
  border-bottom: 1px solid #e9ccba;
  padding-bottom: 7px;
}
.baseFlex{
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.baseFlex small{
  color: #5D6065;
}
.dbprice{
  width: 48%;
}
.expense{
  background-color: #DDD9FF;
  padding: 15px;
  border-radius: 10px;
}
.expense h5{
  border-bottom: 1px solid #d2ccff;
  padding-bottom: 7px;
}

.netincome{
  background-color: #C9EEFF;
  padding: 15px;
  border-radius: 10px;
}
.netincome h5{
  border-bottom: 1px solid #bae2f5;
  padding-bottom: 7px;
}

/* -------------------------dashboard-end------------------------ */


/* -----------------------Add-customer-start------------------------ */
.customerModal{
  text-align: center;
}
.customerModal p{
  margin: 0;
}
.customerModal .modal-header{
  padding: 0;
  border: none;
}
.customerModal .modal-header button{
  position: absolute;
  z-index: 9;
  right: 17px;
  top: 17px;
  font-size: 11px;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding: 5px;
  color: var(--theme-blue);
}
.customerModal .modal-title{
  width: 100%;
  text-align: center;
  padding: 20px 10px 5px;
}
.customerModal .modal-header h5{
  color: var(--theme-blue);
  margin: 0;
}
.modalContent{
  border: 1px dashed #a5c1fd;
  margin: 0 40px 20px 40px;
  padding: 30px;
}

.modalsubmit{
  background-color: var(--theme-blue);
  font-size: 15px;
}
.downloadformat{
  background-color: transparent;
  color: var(--theme-orange);
  font-size: 13px;
}
.downloadformat:hover{
  background-color: transparent;
  color: var(--blueneed);
}
.drgdrop{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.drgdrop p{
  font-size: 14px;
}
.drgdrop i{
  font-size: 25px;
  color: var(--theme-blue);
}
.drgdrop button{
  margin-left: 5px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--theme-blue);
  border-bottom: 1px solid var(--theme-blue);
  font-size: 14px;
  line-height: 20px;
}

/* -----------------------Add-customer-end------------------------ */
.addSupply{

}
.addsuppBtn{
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.addsuppBtn button{
  width: 49%;
  background-color: var(--white);
  border: 1px solid var(--white);
  color: var(--border-color);
}
.addsuppBtn button.active{
  border-color: var(--theme-orange);
  color: var(--theme-orange);
}
.addsuppBtn button:hover{
  background-color: var(--theme-orange);
  color: var(--white);
}
.subTotal{
  background-color: #f1f2f9;
  height: 100%;
  padding: 10px;
}
.txtHeight textarea{
  height: 40px!important;
}
.addsBtn button:hover{
  background-color: var(--theme-orange);
  color: var(--white);
}
.adsDrag{
  border: 1px dashed var(--authformbg);
  margin: 10px 0 20px;
  padding: 50px;
}
.adsDrag p{
  margin: 0;
}
.adsDrag button{

}
.saflex{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--authformbg);
  padding:7px;
  color: #48597D;
  font-size: 14px;
}
.totlbg{
  background-color: #BEC9E0;
}

.supCom{
  text-align: center;
  padding-bottom: 0px;
  position: relative;
  top: 10px;
}
.supCom input[type="text"]{
  background-color: var(--white);
  border: none;
  border-radius: 40px 40px 0 0;
  height: 45px;
  text-align: center;
  color: var(--border-color);
  outline: none!important;
  width:100%;
  font-size: 20px;
}
.supCom input[type="text"]::placeholder{
  color: var(--theme-orange);
}
.productServList{
  padding: 20px;
}
.delModal{
  text-align: center;
}
.delModal .modal-header{
  justify-content: center;
  padding-bottom: 5px;
}
.delModal .commonButton{
  background-color: var(--theme-orange);
}
.delModal .commonButton:hover{
  background-color: var(--theme-blue);
}
.delModal .modal-header button{
  font-size: 12px;
    padding: 5px;
    position: absolute;
    right: 15px;
    top: 22px;
}
.gridList button i{
  padding: 4px;
}
.listactive i, .gridactive i{
background-color: var(--theme-orange);
color: var(--white);
}
.moreDrop{
  background-color: #dde2ed!important;
}
.flexfield{
  display: flex;
  white-space: nowrap;
  align-items: center;
}
.sortby{
  background-color: #dde2ed!important;
  border-radius: 50px!important;
  color: #98999b!important;
}
.dropRow button{
background-color: transparent!important;
border: none;
color: var(--border-color)!important;
padding: 0;
}
.dropRow button::after{
  display: none;
}
.dropRow .dropdown-item{
  font-size: 14px;
  color: var(--text-color);
}
.dropRow .dropdown-item:hover{
 background-color: var(--light-orange);
 color: var(--theme-orange);
}
.pending{
  background-color: var(--lightgreen);
  color: var(--darkgreen);
  padding: 2px 15px;
  display: inline-block;
  border-radius: 50px;
  width: 120px;
  text-align: center;
}
.cancelled{
  background-color: var(--lightred);
  color: var(--darkred);
  padding: 2px 15px;
  display: inline-block;
  border-radius: 50px;
}
.generated{
  background-color: var(--lightblue);
  color: var(--darkblue);
  padding: 2px 15px;
  display: inline-block;
  border-radius: 50px;
}


.datepickr .react-datepicker-wrapper, .datepickr input{
  width: 100%;
}
.datepickr input{
  background:#f1f2f9 url(../images/dt.png) 100% 0 no-repeat;
  background-size: 42px;
  cursor: default;
}
.datepickr .react-datepicker__triangle{
  transform: none!important;
  left: 50px!important;
}
.lightBlock{
  background-color: var(--lightblue);
  display: inline-block;
  padding: 5px 10px;
  font-weight: 300;
  color: var(--text-color);
  max-width: 120px;
  line-height: 20px;
}
.lightBlock2{
  background-color: var(--lightblue);
  display: inline-block;
  padding: 5px 5px;
  font-weight: 300;
  color: var(--text-color);
  max-width: 70px;
  line-height: 20px;
}
.lightBlock3{
  background-color: var(--lightblue);
  display: inline-block;
  padding: 5px 5px;
  font-weight: 200;
  color: var(--text-color);
  max-width: 50px;
  line-height: 20px;
}
.lightBlock4{
  background-color: var(--lightblue);
  display: inline-block;
  padding: 5px 5px;
  font-weight: 200;
  color: var(--text-color);
  max-width: 100px;
  line-height: 20px;
}
.salesOrdTable{
  margin-bottom: 20px;
}
.salesOrdTable thead{
  box-shadow: 0 1px 10px #e3e5f1;
}
.sloModal .modal-footer{
  background-color: var(--lightblue);
}
.salesPopTable th{
  background-color: var(--lightblue)!important;
  border-radius: 0!important;
  color: var(--menu-color)!important;
}
.salesPopTable td{
  background-color: var(--white)!important;
}
.slSearch{
  width: 100%;
 max-width: 400px;
 margin: 0 auto;
 display: flex;
 align-items: center;
 font-size: 15px;
}
.slSearch i{
  position: relative;
  left: 15px;
  top: 5px;
}
.slSearch input[type="search"]{
  border: none!important;
 border-bottom: 1px solid #ccc!important;
 border-radius: 0!important;
 text-align: center;
 width: 100%;

}
.pgns a{
  border: none!important;
  color: var(--copyright);
}
.salesExpCol{
  display: flex;
}
.explft{
  padding: 20px;
  flex: 1;
}
.expRgt{
  background-color: var(--lightblue);
  padding: 20px;
  width: 300px;
  border-radius: 0 10px 10px 0;
  padding-top: 40px;
}
.expRgt .modal-header{
  padding: 0;
  border: none;
}
.expRgt .modal-header button{
  padding: 0;
  width: 20px;
  margin-bottom: 8px;
}
.expRgt button{
  width: 100%;
  margin-bottom: 15px;
}

.wtBtn {
  background-color: var(--white);
  color: var(--darkblue);
  font-size: 15px;
  padding: 5px 20px;
}
.btnshadow{
  box-shadow: 0 0 12px #a4b4d5;
}
.salesModtop{
  display: flex;
  justify-content: space-between;
}
.salesModRgt{
  text-align: right;
}
.salesModRgt strong{
  font-weight: 500;
}
.salesModtop h5{
  color: var(--subhd);
  margin: 0;
}
.salesModRgt small{
  color: var(--subhd);
}
.bsOrder{
  display: flex;
  border-top: 1px solid #eee;
  margin-top: 15px;
  margin-bottom: 20px;
}
.bsOrderlft{
  border-right: 1px solid #eee;
  padding-top: 15px;
  padding-right: 15px;
  width: 50%;
}
.bsOrder h5{
  color: var(--theme-orange);
}
.bsOrder h6, .bsOrder small{
  color: var(--subhd);
}
.bsOrderRgt{
  padding-top: 15px;
  width: 50%;
  padding-left: 15px;
}
.bsTables{
  width: 100%;
  overflow: auto;
}
.bsTables table{
  width: 100%;
}
.bsTables th{
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  color: var(--subhd);
  background-color: #BEC9E0;
  padding: 5px 10px;
}
.bsTables td{
  background-color: var(--lightblue);
  font-size: 14px;
  font-weight: 400;
  color: var(--subhd);
  padding: 5px 10px;
}
.bsTables td h6{
  margin: 0;
  font-size: 14px;
}
.bsTables tfoot td{
  background-color: #BEC9E0;
  font-weight: 500;
}
.bsTables tbody tr:first-child td{
  border-bottom: 1px solid #ccc;
}
.bsTables tbody tr:first-child td:first-child{
  border-bottom: 0;
}
.bsTables td:last-child{
  text-align: right;
}
.redColor{
  color: var(--red);
}
.salesRform input{
  border-radius: 0!important;
  background-color: #ced5e7;
  border: none!important;
  outline: none;
  box-shadow: none;
}
.salesRform textarea{
  height: 200px;
  border-radius: 0!important;
  background-color: #ced5e7;
  border: none!important;
  outline: none;
  box-shadow: none;
  resize: none;
}
.salesRform label{
  padding-bottom: 6px;
}
.salesCheck{
  display: flex;
}
.salesRform input[type="checkbox"]{
  background-color: #b3b9c9;
  margin-right: 5px;
}
.salesExpCol .modal-header{
 position: absolute;
 right: 0;
 top: 0;
 z-index: 1;
 border: none!important;
}
.noBackbtn h5 a{
  display: none;
}

.customDropdown{
  position: relative;
  color: #7E89A1;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    cursor: default;
}
.customDropdown .custom-dropdown-options{
  position: absolute;
  z-index: 9;
  left: 0;
  top: 23px;
  border-radius: 6px;
  background-color: var(--white);
  box-shadow: 0 0 5px #ccc;
  white-space: nowrap;
  overflow: hidden;
}
.customDropdown .custom-dropdown-options li{
  margin: 0;
  padding: 0;
}
.customDropdown .custom-dropdown-options li a{
  margin: 0;
  padding: 10px 18px;
  border: none!important;
  display: block;
}
.customDropdown .custom-dropdown-options li a::after, .customDropdown .custom-dropdown-options li a::before{
  display: none;
}
.customDropdown .custom-dropdown-options li a:hover{
  color: var(--theme-orange);
  background-color: var(--light-orange);
}
.customDropdown .active{
  color: var(--theme-blue);
  position: relative;
}
.customDropdown .active::after{
    content: "";
    position: absolute;
    left: 0;
    top: 29px;
    width: 100%;
    height: 2px;
    background-color: var(--theme-blue);
    display: block;
}

.fa-trash-can{
  color: var(--theme-orange);
}
.fa-times-circle{
  color: var(--theme-orange);
}
.fa-trash-can:hover{
  color: var(--red);
}
.loadItems{
  text-align: center;
  padding: 10px;
}
.datepickBk input{
background-color: #e0e3f1;
padding-left: 15px;
width: 140px;
font-size: 13px;
}
.datepickBk button{
  display: none;
}
.react-datepicker__header{
  background-color: var(--lightblue)!important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background-color: var(--theme-orange)!important;
  color: var(--white)!important;
  font-weight: 500;
}
.invoiceCreate{
  position: relative;
}
.unAtr{
  color: var(--red);
  position: absolute;
  left: 20px;
  top: 10px;
  z-index: 9;
}


.civModal input:focus{
 background-color: var(--lightblue);
}
.invDatepicker input{
 padding-left: 15px;
}
.invDatepicker button:hover{
  background-color: transparent;
}


.vatModal{
  text-align: left;
}
.vatModal .modal-title{
  text-align: left;
  padding-left: 30px!important;
  display: flex;
  align-items: center;
}
.vatModal .modal-body{
  padding-left: 30px;
  padding-right: 30px;
}
.vatModal .modal-footer{
  display: flex;
  justify-content: space-between;
}
.vatModal .modal-footer a{
  color: var(--text-color);
}
.vatModal .modal-footer a:hover{
  color: var(--blueneed);
}

.invLft, .invrgt{
  position: relative;
}
.invBasis label{
  padding: 10px 30px!important;
  font-size: 16px;
  color: var(--darkblue)!important;
  border-left: 1px solid #dedfe3;   
  min-width: 150px; 
  text-align: center;
}
.invLft label{
  border: none;
}
.invBasis label::before{
left: 0;
width: 100%;
height: 100%;
}
.invBasis label::after{
  background-color: #d8dfef!important;
  left: 0;
width: 100%!important;
height: 100%!important;
right: inherit!important;
} 
.invBasis h6, .invBasis label{
  color: var(--darkblue)!important;
  /* padding-left: 0!important; */
}
.yesNo{
  background-color: var(--white);
  box-shadow: 0 0 5px #ccc;
  border-radius: 50px;
  overflow: hidden;
}
.yesNo label{
position: relative;
padding: 5px 15px!important;
text-align: center;
}
.yesNo label::before{
left: 0;
width: 100%;
height: 100%;
background-color: var(--white);
}
.yesNo [type="radio"]:checked + label:after{
  left: 0!important;
  width: 100%!important;
  height: 100%!important;
  background-color: var(--theme-orange)!important;
  right: inherit!important;
  }

.backBtn{
  color: var(--text-color);
  border: none;
  outline: none;
  background-color: transparent;
}
.invcreateModal .modal-title{
  text-align: left;
  padding: 15px
}
.invHead{
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  align-items: center;
}
.invHead button{
  position: static!important;
  color: var(--white)!important;
  font-size: 15px!important;
}
.invHead .slSearch{
  width: auto;
  margin: 0;
  position: relative;
}
.invHead input[type="search"]{
 height: 40px;
 box-shadow: 0 0 5px #e9efff!important;
 border-radius: 12px!important;
 border: none!important;
 padding-left: 35px!important;
}
.invHead .slSearch i{
 position: absolute;
 left: 15px;
 top: 13px;
}
.darktxt{
  color: var(--blueneed);
}
.commonFlex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
}
.commonFlex h6{
  margin: 0;
  font-size: 18px;
  color: var(--darkblue);
}
.commonFlex span{
  font-size: 13px;
}
.commonFlex span.viewIcon{
  font-size: 15px;
}
.customr{
  color: var(--theme-orange);
}
.gridFooter{
  border-top: 1px solid #ccc;
  padding-top: 15px;
  margin-top: 15px;
  font-size: 13px;
}
.invgridicn{
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 20px;
}
.invgridicn i{
  color: var(--theme-orange);
}
  


/* Feedback-changes-start-25-01-2024 */
.register{
  padding: 10px 40px;
}
.register .form-control{
  height: 35px;
  font-size: 14px!important;
}
.register .fieldBlock label{
  top: 4px;  
}
.zindex label{
  z-index: 1;
}
.register input[type="tel"]{
  height: 25px;
}
.register .phoneInputDropdown button{
  height: 25px;
}
.register .fieldBlock .css-13cymwt-control{
  height: 35px;
}
.register .fieldBlock .css-13cymwt-control input{
  font-size: 14px;
}
.register .css-1dimb5e-singleValue{
  font-size: 14px;
}
.register .commonButton{
  padding: 5px 15px;
}
.subsMain{
  max-width: 1024px;
}
.mbdtl{
  background-color: var(--white);
}
.mbdtl ul{
  padding-bottom: 20px!important;
}
.mbdtl h5{
  font-size: 18px;
  border-bottom: 1px solid #ccc;
  position: relative;
}
/* .mbdtl h5::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border-right: 50px solid #ccc;
} */
.mbdtl ul li{
  position: relative;
  padding-left: 20px;
  padding-bottom: 15px;
}
.mbdtl ul > li:before {
  content: "-";
  left: 0;
  position: absolute;
}
.backMain{
  margin-top: 20px;
  /* padding: 10px 20px;
  border-top: 1px solid #ccc;
  margin-top: 30px;
  background-color: var(--theme-blue); */
}
.backMain a{
  color: var(--white);
  background: var(--blueneed);
  padding: 8px 15px;
  border-radius: 5px;
}
.backMain a:hover{
  background-color: var(--theme-orange);
}
.mbImg{
  padding: 0;
  position: relative;
}
.mbImg img{
  /* width: 100%;
  left: 0;
  padding: 0;
  height: 100%;
  object-fit: cover; */
  max-width: 350px;
  position: static;
  /* border: 1px solid #00476c; */
  border-radius: 5px;
}
.mbSecondimg{
  position: absolute;
  left: 200px;
  bottom: -89px;
}
.mbSecondimg img{
  width: 250px;
}
.taxTxt{
  /* overflow: auto;
  max-height: 300px; */
}
.taxTxt::-webkit-scrollbar{
  width: 3px;
}
.manageBase{
  display: flex;
  align-items: flex-start;
}

.taxTxt li{
  position: relative;
  padding-left: 20px;
  padding-bottom: 20px;
  color: var(--white);
  line-height: 24px;
  font-size: 17px;
  font-weight: 400;
}
.taxTxt > li:before {
  content: "";
  font-family: "Font Awesome 6 Pro";
  left: 0;
  position: absolute;
  color: var(--white);
  background: #791075;
  border-radius: 100px;
  width: 7px;
  height: 7px;
  top: 8px;
}
.taxRgt .taxTxt > li:before{
  background: #fff;
}

.mbdHead{
  background-color: var(--theme-blue);
  text-align: center;
  color: var(--white);
  padding: 20px 15px;
  margin-bottom: 20px;
}
.mbdHead img{
  width: 100%;
  border: 4px solid var(--white);
}
.authBg h4{
  color: var(--white);
}
.mbRgt{
  background-color: transparent;
  padding: 0;
}
.mbRgt h4{
  color: var(--border-color);
}
.mbRgt li{
  color: var(--footer-txt);
}
.mbRgt li::before{
  color: var(--border-color);
}
.manageBusinessBlock{
  background-color: transparent;
  padding-top: 80px;
}

.mbRgt h6{
  color: var(--border-color);
}
/* Feedback-changes-end-25-01-2024 */


.addresscode{
  background-color: #134084;
  height: 100%;
  padding: 30px;
  border-radius: 5px;
}
.addresscode h5, .addresscode p, .addresscode h6{
  color: var(--white);
}
.fheight{
  height: 100%;
}
.contactContainer{
  background: url(../images/map.png) 0 0 no-repeat;
  background-size: cover;
}
.pieChartBg{
  width: 100%;
  height: 100%;
}

.barChartbg{
  width: 100%;
  height: 100%;
  padding: 20px;
}
.lineChartbg{
  width: 100%;
  height: 100%;
  padding: 20px; 
}
.salesblock h5{
  color: var(--theme-blue);
}
.salesblock i{
  color: var(--theme-blue);
  margin-bottom: 10px;
  font-size: 40px;
}
.salesblock .slideBlock{
  padding: 25px;
}
.contFrmbg{
  /* overflow: auto; */
}
.fieldTextarea textarea{
  height: 100px!important;
  resize: none;
  border-radius: 15px!important;
}

.billTextarea textarea{
  height: 120px;
}

.actTop{
  background-color: var(--white)!important;
  border-top:5px solid var(--authbg)!important;
  text-align: left!important;
  font-size: 15px!important;
}
.actTop2{
  text-align: left!important;
  font-size: 18px!important;
}
.my-confirm-button {
  background-color: #3F63AF !important; /* Change to your desired color */
  color: white !important;
  border: none !important;
}
/* .debt{
  color: var(--red);
} */
.credt{
  color: #76BB1E;
}
.nobgColor{
  background-color: transparent!important;
}

.custom-dropdown{
  position: relative;
  
}
.custom-dropdown .dropdown-menu{
  position: absolute;
    z-index: 999;
    /* left: 0; */
    top: 38px;
    background: #fff;
    display: block;
    box-shadow: 0 0 5px #ccc;
}
.custom-dropdown .dropdown-menu li a{
  display: block;
  padding: 5px 15px;
  font-size: 15px;
  color: var(--copyright);
}
.custom-dropdown .dropdown-menu li a:hover{
  background-color: var(--light-orange);
  color: var(--theme-orange);
}
.circldropdown .dropdown-toggle{
  background-color: #dde2ed!important;
  border-radius: 50px!important;
  color: #98999b!important;
  padding: 5px 15px;
  cursor: default;
}
.circldropdown .dropdown-menu{
  right: 0;
  left: inherit;
}

.modAddbtn{
  top: 70px!important;
  color: var(--white)!important;
  font-size: 14px!important;
}
.modAddbtn2{
  top: 40px!important;
  right: 28px!important;
  color: var(--white)!important;
  font-size: 14px!important;
}
.tabsFrame .nav-item button{
  position: relative;
  border: none;
  background: var(--white);
  margin-right: 25px;
  position: relative;
  border-radius: 6px 6px 0 0;
  padding: 6px 2px 6px 15px;
  color: var(--theme-blue);
  font-size: 17px;
  margin-top: 5px;
}
.tabsFrame .nav-item button::after{
  content: "";
    position: absolute;
    z-index: 0;
    left: 100%;
    bottom: 0;
    /* height: 100%; */
    /* background-color: #000; */
    border-left: 23px solid var(--white);
    border-top: 38px solid transparent;
}
.tabnumber{
  color: var(--theme-blue);
  background-color: #F2F4FB;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
}
.tabsFrame .nav-item button.active{
  background-color: var(--theme-orange);
  color: var(--white);
}
.tabsFrame .nav-item button.active::after{
  border-left: 23px solid var(--theme-orange);
}
.tabsFrame .tab-content{
  background-color: var(--white);
  padding: 15px;
}
.tabsblock{
  box-shadow: 0 0 5px #ebf1ff;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 15px;
}
.tabTop{  
  padding: 9px 10px;
  display: flex;
  justify-content: space-between;
}
.tabTop h5{
  color: var(--theme-blue);
  margin: 0;
}
.tabTxt{
  padding: 10px;
  background-color: #f2f3f9;
  font-size: 15px;
  color: #48597D;
}
.tabTop i{
  color: var(--theme-orange);
}
.editaccount{
  border: none;
  outline: none;
  background-color: transparent;
}
.rightBtn{
  text-align: right;
}
.asdModal{
  background-color: rgba(0,0,0,0.5);
}
.sheetdate{
  font-size: 14px!important;
  line-height: 22px;
}
.balanceSheet td:last-child{
  text-align: right!important;
}
.cash{
  background-color: var(--theme-blue);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  margin-bottom: 15px;
}
.cashblock{
  margin-right: 20px;
}
.cashtag{
  background-color: #123172;
  color: #91B2F9;
  border-radius: 20px;
  padding: 5px 15px;
  margin-bottom: 5px;
  display: inline-block;
  min-width: 150px;
  text-align: center;
}
.cashTxt{
  font-size: 28px;
  line-height: 30px;
  font-weight: 500;
  color: var(--white);
  display: flex;
  align-items: flex-start;
}
.cashTxt i{
  font-size: 32px;
  margin-left: 10px;
}
.cashtp{
  display: flex;
  color: var(--theme-orange);
  align-items: center;
  position: relative;
  top: 37px;
}
.cashtp i{
  font-size: 22px;
  margin-right: 10px;
}
.profileTop{
  display: flex;
  padding-bottom: 25px;
  align-items: center;
  justify-content: space-between;
}
.profileUpload{
  display: flex;
  align-items: center;
}
.profileUpblock{
  position: relative;
  text-align: center;
  box-shadow: 0 0 8px #dce5fd;
  background-color: #eee;
  border-radius: 5px;
  /* overflow: hidden; */
  border: 1px solid var(--white);
}
.profEdit{
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: -13px;
  transform: translate(-50%);
  background: var(--theme-blue);
    border-radius: 50px;
    width: 25px;
    height: 25px;
    display: inline-flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.productdata{align-items: center;}
.profileUpblock h6{
  color: #48597D;
}
.countryField{
position: relative;
  z-index:9;
  
}
/* .countryField2{
  position: relative;
  z-index:55;
} */
.mytick{
  /* position: absolute; */
  display: inline-block;
  right: 0;
  top: 0;
  background-color: #4BAE4F;
  color: var(--white);
  border-radius: 50px;
  padding: 3px;
  width: 22px;
  height: 22px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
}
.aboutBnr{
  background-color: var(--theme-blue);
  padding: 20px 0;
  color: var(--white);
}
.pricinghome .navigationBg {
  background-color: var(--white);
}
.featinvoiceHeight{
  height: 84px;
}
.featInvBnr{
  padding-bottom: 100px;
}
.seamLess{
    display: flex;
    padding-bottom: 15px;
}
.featInvChasing h4{
  color: var(--border-color);
  font-weight: 400;
}
.smCircle{
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  background-color: #BA91CD;
  margin-right: 15px;
  font-size: 25px;
  color: #791075;
}
.manageInvaccount{
  background-color: var(--theme-blue);
  padding: 40px 0;
}
.manageInvaccount h2{
  font-size: 40px;
  color: var(--white);
  font-weight: 200;
}
.manageInvaccount p{
  color: var(--white);
}
.mvTrack{
  background: linear-gradient(to right,  rgba(31,65,137,1) 0%,rgba(63,99,175,0) 100%);
  color: var(--white);
  border-radius: 5px;
  padding: 7px 10px;
  font-size: 17px;
  line-height: 25px;
}






.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style individual items */
.my-masonry-grid_column > div {
  /* your item styles */
}






.masBlock{
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  background-color: var(--white);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 3px #ccc;
}
.masBlock img{
  /* width: auto; */
  width: 100%;
  max-width: 100%;
}
.masCard{
  padding: 18px;
  text-align: left;
}
.masCard h5{
  font-size: 16px;
  color: var(--theme-blue);
  text-transform: uppercase;
}
.masCard h5 span{
  text-transform: none;
} 
.masCard h3{
  display: -webkit-box;
  max-height: 5.07rem;
  /* Fallback for non-webkit */
  font-size: 18px;
  line-height: 1.3;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}
.masCard a{
  color: var(--theme-orange);
}
.listScroll{
  height: 250px;
  overflow: auto;
}
.detlBnr img{
  width: 100%;
  border-radius: 5px;
}
.detlTxt{
  padding: 10px 0;
}
.delRedtxt{
  color: #EA1D3E;
}
.delRedtxt i{
  margin-right: 12px;
}
.delRedtxt small{
  margin-right: 18px;
}
.delRedtxt p{
  color: #828998;
}
.blogQuote{
  display: flex;
  padding-bottom: 20px;
  align-items: flex-start;
}
.blogQuote img{
  flex: none;
}
.blogSlogan{
  padding-left: 35px;
  font-style: italic;
  font-weight: 600;
  color: var(--theme-black);
}
.blogSlogan span{
  color: #EA1D3E;
}
.relatedBlog img{
  height: 250px;
  object-fit: cover;
}
.relatedBlog h5{
  /* padding-bottom: 15px; */
  color: var(--darkblue);
}
.modIframe{
  position: relative;
  height: 420px;
  width: 350px;
  flex: 1;
  overflow: hidden;
}
.modIframe iframe{
  position: absolute;
  top: -50px;
  width: 100%;
  bottom: 0;
  height: 470px;
}
.fullHgt{
  height: 100%!important;
}
.backArrow{
  background-color: transparent!important;
  width: auto!important;
  min-width: auto!important;
  padding: 0;
  color: var(--theme-black);
}
.backArrow:hover{
  color: var(--theme-orange);
}

.newPricing{
  max-width: 1200px;
}


.mulFiles ul{
  display: flex;
  flex-wrap: wrap;
}
.mulFiles ul li{
  margin-right: 10px;
  padding: 5px 10px;
  background-color: var(--light-orange);
  border-radius: 3px;
  font-size: 14px;
  position: relative;
  margin-bottom: 2px;
}
.mulFiles ul li button{
  border: none;
  outline: none;
  background-color: transparent;
  box-shadow: none;
  font-size: 13px;
  position: absolute;
  right: -5px;
  top: -5px;
  color: #ff0000;
}
.mulFileselect{
  border: 1px dashed var(--authformbg);
  position: relative;
  height: 42px;
  padding: 10px 15px;
  font-size: 15px;
  color: var(--theme-blue);
  margin-bottom: 10px;
}
.mulFileselect i{
  font-size: 22px;
  color: var(--theme-blue);
  margin-right: 5px;
}
.mulFileselect input{
  opacity: 0;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}


.accFlex{
  /* display: flex; */
  justify-content: space-between;
}
.accFlex > .tabTxt{
 /* width: 49%; */
 position: relative;
 margin-bottom: 10px;
}
.accFlex .tabTxt button.editaccount{
 position: absolute;
 right: 10px;
 top: 10px;
 color: #16A2F3;
}
.tabTxtinn{
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}
.tabTxtinn2{
  display: flex;
 
  padding-bottom: 5px;
}
.wv-divider {
  display: block;
  height: 0;
  padding: 0;
  border: none;
  border-bottom: 1px solid #d4dde3;
  overflow: hidden;
  margin: 24px auto;
}
.tabTxtinn i{
  color: #16A2F3;
}




/* MODIFY-TABS */
.tabModify .nav-item button{
  margin-right: 10px;
  padding: 10px 25px;
  margin-bottom: 0!important;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.tabModify .nav-item button::after{
  display: none;
  margin-bottom: 20px;
}
.tabModify img{
  margin-bottom: 4px;
  height: 20px;
}
.tabModify .tabnumber{
  margin: 5px 0 0 0;
}





















.impDrop{
  display: flex;
  align-items: center;
}
.impDrop label{
  font-size: 16px;
  padding-right: 6px;
  display: inline-block;
}

.impDrop select{
  appearance: auto;
  min-width: 150px;
  height: 40px;
}


.slctCurrency {
  width: 100%;
  border-radius: 50px;
  border: 1px solid #D6E3FF;
  height: 40px;
  margin-bottom: 2px;
  background: url(../images/downarrow2.png) calc(100% - 10px) 10px no-repeat;
  background-size: 20px;
}











/* ------------------------Account-Chart-New-Menu-Start--------------------------- */
.menuContainer {
  width: 100%;
  /* background-color: #f5f5f5; */
  padding: 0px;
}

.menuItem {
  padding: 15px;
  cursor: pointer;
  background-color: #fff;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  border: 1px solid #e05425;;
  border-radius: 5px;
  font-size: 17px;
  line-height: 26px;
  font-weight: 500;
  color: #7E89A1;  
  display: flex;
  align-items: flex-start;
  justify-content: space-between;  
}
.arrw{
  transition: all 0.2s;
}
.menuItem.active .arrw{
  rotate: 90deg;
  transition: all 0.2s;
}
.menuItem > img{
  width: 26px;
}
.menuTxt{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.menuTxt img{
  width: 27px;
  margin-right: 10px;
}
.menuTxt h6{
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #7E89A1;
  margin: 0;
}

.menuItem:hover,
.menuItem.active {
  background-color: #e05425;;
  color: #fff;  
}
.menuItem.active{
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #fff;
}
.menuItem:hover h6,
.menuItem.active h6{
  color: #fff;
}
.menuItem:hover img,
.menuItem.active img{
  filter: invert(100%) sepia(0%) saturate(7447%) hue-rotate(185deg) brightness(220%) contrast(200%);
}

.childMenu {
  margin-left: 0;
  margin-top: -10px;
  background: #e05425;;
  padding: 10px 10px 10px 52px;
  border-radius: 0 0 5px 5px;
  margin-bottom: 10px;
  transition: all 0.2s;
}

.childMenuItem {
  padding: 5px;
  cursor: pointer;
  margin-bottom: 3px;
  transition: background-color 0.3s ease;
  color: #fff;
  font-size: 17px;
  font-weight: 300;
}
.childMenuItem i{
  font-size: 14px;
  margin-right: 5px;
}

.childMenuItem:hover,
.childMenuItem.active {
  background-color: #e05425;;
  border-radius: 0 50px 50px 0;
}

.contentArea {
  margin-left: 20px;
  padding: 20px;
  flex: 1;
}
/* ------------------------Account-Chart-New-Menu-Start--------------------------- */
.chartRight{
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 3px #eee;
  padding: 20px;
}
.chartRight h4{
  color: #7E89A1;
}
.chartRight h4 span{
  color: #e05425;;
}
.chrtBox{
  background-color: #F2F3F9;
  border-radius: 5px;
  padding: 20px;
  margin-top: 15px;
}
.chrtBox h5{
  color: #48597D;
  border-bottom: 1px solid #48597D;
  padding-bottom: 15px;
  color: #48597D;
  font-size: 22px;
  margin-bottom: 10px;
}
.chrtBox p{
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #48597D;
}











  /* -------------FOOTER-CSS-START------------ */
  .footerB{
    background-color: var(--body-color);
    padding: 40px 0 25px 0;
  }
  .footerB ul li{
    padding-top: 12px;
    font-size: 14px;
  }
  .footerB ul li a{
    font-weight: 400;
    color: var(--footer-txt);
    text-transform: uppercase;
  }
  .footerB ul li a:hover{
    color: var(--theme-orange);
  }
  .footerLogo img{
    width: 200px;
  }
  .footerFix{
    background-color: var(--white);
    border-top: 1px solid var(--lightborder);
    padding: 15px;
    text-align: center;
    color: var(--copyright);
    font-size: 14px;
    font-weight: 500;
    position: fixed;
    z-index: 9;
    bottom: 0;
    width: 100%;
  }
  .footerFix a{
    color: var(--copyright);
  }
  .footerFix a:hover{
    color: var(--theme-blue);
  }
  .socialBg{
    padding: 20px 0;
  }
  .socialBg a{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: var(--theme-blue);
    border-radius: 100px;
    width: 35px;
    height: 35px;
    border: 1px solid var(--text-color);
    margin-right: 8px;
  }
  .socialBg a:hover{
    box-shadow: var(--boxshadow);
  }
  .footerB p{
    color: var(--footer-txt);
    font-size: 16px;
  }
  .footerB h4{
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--footer-heading);
  }
  .footerStore img{
    /* width: 100%; */
  }
   /* -------------FOOTER-CSS-END------------ */


   .scrollTop{
    position: fixed;
    z-index: 99;
    right: 20px;
    bottom: 40px;
    background: var(--theme-orange);
    color: var(--white);
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 100px;
    box-shadow: var(--boxshadow);
    width: 34px;
    height: 34px;
    padding: 6px;  
    animation: MoveUpDown 3s linear infinite;  
   }
   .scrollTop:hover{
    background-color: var(--theme-black);
   }

   @keyframes MoveUpDown {
    0%, 100% {
      bottom: 20px;
    }
    50% {
      bottom: 40px;
    }
  }



  .pnf{
    background: url("https://3.bp.blogspot.com/-zjgN8Jd79e4/TV8aaF8PhwI/AAAAAAAADH4/760FpC9CnAo/s1600/lautan-biru.jpg") no-repeat center center;
    background-size: cover;	   
    width: 100%;
    height: calc(100vh - 50px);
  	overflow: hidden;
  }
  .man{
    position: absolute;
    left: 0;
    bottom: 2em;
    opacity: 0.9;
  }
  .bannr {
    position:absolute;
    bottom: 12.5em;
    left: 1.5em;
  }
  .man {
    -webkit-animation: man-move 3s ease-in-out infinite;
    -moz-animation: man-move 3s ease-in-out infinite;
    -o-animation: man-move 3s ease-in-out infinite;
    animation: man-move 3s ease-in-out infinite;
  }
  .bannr{
    -webkit-animation: banner-move 3s ease-in-out infinite;
    -moz-animation: banner-move 3s ease-in-out infinite;
    -o-animation: banner-move 3s ease-in-out infinite;
    animation: banner-move 3s ease-in-out infinite;
  }
  @-webkit-keyframes man-move {
    50%  { -webkit-transform: rotate(-5deg) translateY(-10px);}
  }
  @-moz-keyframes man-move {
    50%  { -moz-transform: rotate(-5deg) translateY(-10px);}
  }
  @-o-keyframes man-move {
    50%  { -o-transform: rotate(-5deg) translateY(-10px);}
  }
  @keyframes man-move {
    50%  {transform: rotate(-5deg) translateY(-10px);}
  }
  @-webkit-keyframes bg-move {
    0%   { background-position:  50% -10px; }
    100% { background-position: -200% -10px; }
  }
  @-moz-keyframes bg-move {
    0%   { background-position:  50% -10px; }
    100% { background-position: -200% -10px; }
  }
  @-o-keyframes bg-move {
    0%   { background-position:  50% -10px; }
    100% { background-position: -200% -10px; }
  }
  @keyframes bg-move {
    0%   { background-position:  50% -10px; }
    100% { background-position: -200% -10px; }
  }
  @-webkit-keyframes banner-move {
    50% { -webkit-transform: rotate(-8deg) translateY(-10px);}
  }
  @-moz-keyframes banner-move {
    50% { -moz-transform: rotate(-8deg) translateY(-10px);}
  }
  @-o-keyframes banner-move {
    50% { -o-transform: rotate(-8deg) translateY(-10px);}
  }
  @keyframes banner-move {
    50% { transform: rotate(-8deg) translateY(-10px);}
  }














  /* RESPONSIVE-CSS */
  @media screen and (max-width: 1199px) {
    .mbImg, .mbRgt{
      width: 50%!important;
    }
    .mbImg img{
      max-width: 350px!important;
    }
    .listViews{
      overflow: auto;
      max-width: 991px;
    }
    .listViews table{
      width: 1000px;
    }
    .taxbase{
      flex-direction: column;
    }
    .taxLft{
      width: 100%;
    }
    .taxLft img{
      right: inherit;
      max-width: 100%;
    }
    .taxRgt{
      width: 100%;
    }
    .authLogo::before{
      font-size: 100px;
      top: 55px;
    }
    .bannerBg img{
      max-width: 527px;
    }
    .bannerBg{
      min-height: 400px;
    }
  }

  @media screen and (max-width: 1099px) {
    .poResponsive{
      flex-direction: column;
    }
    .poResponsive .customSrchfield{
      margin-bottom: 15px;
    }
    .gridList{
      justify-content: center!important;
    }
  }
  
  @media screen and (max-width: 991px) {
    .cashTxt{
      font-size: 20px;
    }
    .cashblock h2{
      font-size: 25px;
    }
    .testimonial .owl-item.active.center {
      transform: scale(1.0)!important;
    }
    .footerB{
      text-align: center;
    }
    .footerB ul li a{
      font-size: 12px;
    }
    .footerB p{
      font-size: 14px;
      padding-bottom: 20px;
    }
    .footerStore img {
      width: 150px;
   }
    .respNav{
      flex-direction: row!important;
      padding: 20px 0;
    }
    .navbarResponsive .dropdown{
      position: relative;
    }
    .navbarResponsive .dropdown-menu{
      position: absolute!important;
      left: 0;
      top: 50px;
      min-width: 200px!important;
    }
    
    .navbarResponsive .navbar-toggler-icon{
      background-image: none;
      position: relative;
    }
    .navbarResponsive .navbar-toggler{
      background-color: var(--darkred);
      border-radius: 100px;
      width: 30px;
      height: 30px;
      padding: 5px;
      margin-bottom: 20px;
    }
    .navbarResponsive .navbar-toggler-icon::before{
      content: "\f00d";
      position: absolute;
      color: #fff;
      font-family: "Font Awesome 6 Pro";
      font-size: 21px;
      line-height: 23px;
      left: 2px;
      top: -2px;
    }
    .navbarResponsive{
      position: fixed;
      background: rgba(0,0,0,0.9);
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    }
    .navigationBg .navbar-nav a{
      color:var(--white);
    }
    .navigationBg .dropdown-menu{

    }
    .navigationBg .dropdown-menu a{
      color: var(--theme-black);
    }
    .featInvBnr{
      padding-bottom: 0px;
    }
    .cfoLogo{
      height: 110px;
    }
    .cfoLogo::before{
      top: 0;
      height: 80px;
    }
    .cfoBnr{
      margin-top: 0;
    }
    .cfoBnr h1{
      padding-top: 0;
    }
    .menuToggle{
      display: block;
    }
    .dbsidemenu{
      height: 100%;
    }
    .ps-sidebar-container{
      background-color: transparent!important;
    }
    .dbsidemenu li .ps-menu-button{
      color: var(--white);
    }
    .ps-collapsed .menuToggle{
      left: 0;
      top: 65px;
      right: inherit;
    }
    .dbsidemenu{
      position: fixed!important;
      z-index: 999;
      left: 0;
      top: 0;
      width: 100%!important;
      padding: 0px;
    background: var(--theme-blue);
    }
    .css-1wvake5.ps-collapsed{

    }

    .manageBase{
      flex-direction: column;
    }
    .mbRgt{
      padding-top: 100px;
      width: 100%!important;
    }

    .custTop .navbar-collapse{
      position: fixed;
      z-index: 9999;
      left: 0; 
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0,0,0,1.0);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .custTop .navbar-collapse .navbar-toggler{
      color: var(--white);
      font-size: 35px;
    }
    .custTop .navbar-collapse a{
      margin: 10px 0 10px 0;
      padding: 5px;
      font-size: 22px;
      color: #96b7ff;
    }
    .custTop .navbar-collapse a.active::after{
      background-color: var(--theme-orange);
    }
    .custTop .navbar-collapse a::before{
      display: none;
    }
    .custTop .navbar-collapse .customDropdown {
        margin-left: 0!important;
        font-size: 22px;
        margin-top: 15px;
        color: #96b7ff;
    }

    .salesExpCol{
      flex-direction: column;
    }
    .expRgt{
      width: 100%;
    }
    
    .restUs{
      padding: 50px 0;
    }
    .testimonial h2::before{
      font-size: 70px;
    }
    .need_a{
      height: auto;
      margin-bottom: 15px;
    }
    .smartAccountbg{
      text-align: center;
    }
    .bannerBg{
      text-align: center;
    }
    h1, .bannerBg h1{
      font-size: 45px;
      line-height: 55px;
    }
    .bannerBg img{
      max-width: inherit;
      position: static;
      width: 100%;
      margin-top: 30px;
    }
    .bannerBg{
      min-height: auto;
    }
  }

  @media screen and (max-width: 800px) {
    .smartAccountbg h2{
      font-size: 25px;
    }
    .signForm{
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    .homefaqBg .accordion-button{
      font-size: 17px;
    }
    .payble, .income, .expense{
      margin-bottom: 10px;
    }
    .plancontent h2{
      font-size: 45px;
    }
    .respnoMrgn{
      margin-left: 0;
      margin-right: 0;
    }
    .cash{
      flex-direction: column;
      align-items: center;
    }
    .cashblock{
      margin: 0 0 15px;
      /* text-align: center; */
      width: 185px;
    }
    .cashtp{
      top: 0;
      flex-direction: column;
      align-items: flex-start;
    }
    .cashTxt {
      font-size: 25px;
      line-height: 28px;
    }
    .pieChartBg canvas{
      /* width: 100%; */
    }
    .barChartbg canvas, .lineChartbg canvas{
      /* width: 100%; */
    }
    .cfoLogo::before{
      top: 0!important;

    }
    .cfoBnr h1{
      font-size: 30px;
      line-height: 38px;
    }
    .gridView .card{
      width: 47%;
    }
    .dbTopsec{
      flex-direction: column;
      align-items: center;
    }
    .customerSrchTop {
      flex-direction: column;
    }
    .customSrchfield{
      margin-bottom: 15px;
    }
    .customerMain .gridList{
      text-align: center!important;
    }
    .custTop{
      flex-wrap: wrap;
      align-items: baseline;
    }
    .custTop a{
      margin-bottom: 25px;
    }
    .dbTopsec{
      justify-content: center;
    }
    .dbHead{
      flex-direction: column;
      align-items: center;
      padding: 5px 10px;
    }
    .logoBg{
      margin-bottom: 10px;
    }
    .pdt {
      padding-top: 91px;
  }
    .testimonial h2::before {
      font-size: 49px;
  }
  .testiTm {
    margin-top: 38px;
}
    .pricingLogo{
      padding-top: 0!important;
      padding-bottom: 0!important;
    }
    .pricingLogo::before{
      top: 13px!important;
    }
    .pricingA{
      padding-bottom: 10px;
    }
    .planBg{
      margin-top: 20px;
      margin-bottom: 10px;
    }
   
    .addRow{
      border-top: 1px solid var(--footer-txt);
      padding-top: 30px;
      margin-top: 15px;
    }
    .delIcon{
      width: 100%;
    }
    .appendbtn{
      margin-top: 15px;
    }
    .homeVideo h2{
      font-size: 27px;
      line-height: 33px;
    }
    .videoBase{
      padding: 0 10%;
    }
    h1, .bannerBg h1{
      font-size: 35px;
      line-height: 45px;
    }
    /* .footerStore img{
      width: auto;
    } */
    .authLogo::before{
      font-size: 60px;
      top: 85px;
    }
    .authLogo img{
      width: 250px;
    }
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
  }

  @media screen and (max-width: 600px) {
    .commonSt {
      width: 31.5%;
      margin-bottom: 6px;
    }
    .manageInvaccount h2{
      font-size: 30px;
    }
    .gridView .card{
      width: 98%;
    }
    .customSrchfield{
      flex-direction: column;
      align-items: center;
      width: 100%;
      display: block;
    }
    .react-datepicker-wrapper{
      width: 100%;
    }
    .customSrchfield input{
      width: 100%;
    }
    .addsuppBtn{
      flex-direction: column;
      align-items: center;
    }
    .addsuppBtn button{
      margin-bottom: 15px;
    }
  }
  

  @media screen and (max-width: 480px) {
    .commonSt{
      width: 48.5%;
    }
    .cfoLogo::before{
      height: 80px;
      line-height: 85px;
    }
    .cfoLogo{
      height: 87px;
    }
    .custButton{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .commonButton{
      /* width: 100%; */
    }
    .custTop a{
      margin-bottom: 0;
      display: block;
      width: 100%;
      margin-bottom: 22px;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }
    .custTop a::before{
      display: none;
    }
    .custTop a:hover::after, .custTop a.active::after{
      top: 24px;
    }
    .custTop .customDropdown{
      margin-left: 0!important;
      width: 100%;
    }
    .salesModtop{
      flex-direction: column;
      text-align: center;
    }
    .salesModRgt{
      text-align: center;
      padding-top: 15px;
    }
    .bsOrder{
      flex-direction: column;
      text-align: center;
      padding-bottom: 15px;
    }
    .bsOrderlft{
      border-right: 0;
      width: 100%;
      padding-right: 0;
    }
    .bsOrderRgt{
      width: 100%;
      padding-left: 0;
    }
    .testimonial .owl-item.active .testiBlock{
      padding: 25px;
    }
    .testimonial h2::before {
      font-size: 36px;
  }
    .choosePlanBtm{
      padding: 0 20px;
    }
    .videoBase{
      padding: 0 2%;
    }
    h1, .bannerBg h1{
      font-size: 30px;
      line-height: 38px;
    }
    .authLogo::before{
      font-size: 40px;
      top: 95px;
    }
    .navbar-brand img {
      width: 190px;
  }

  .brwseFile{
    flex-direction: column;
  }
  .brwseInst{
    padding-left: 0;
  }
    
  }

  @media screen and (max-width: 360px) {
    .commonSt{
      width: 100%;
    }
    .gridListResp{
      flex-direction: column;
    }
    .gridListResp .srtBy{
      margin-bottom: 10px;
    }
    .videoBase{
      padding: 0 0;
    }
    
  }